import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "aiFeaturesContainerRef",
  class: "ai-features"
}
const _hoisted_2 = { class: "indicator-list" }
const _hoisted_3 = {
  key: 0,
  class: "items-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "indicator-list-header" }, [
        _createTextVNode(" L'Intelligence Artificielle"),
        _createElementVNode("br"),
        _createTextVNode(" à votre service ")
      ], -1 /* HOISTED */)),
      _createElementVNode("button", {
        class: _normalizeClass(['indicator-item', { active: $setup.currentIndex === 0 }]),
        onClick: _cache[0] || (_cache[0] = $event => ($setup.currentIndex = 0))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "indicator-item-slot" }, null, -1 /* HOISTED */),
        _createElementVNode("div", { class: "indicator-item-label" }, [
          _createTextVNode(" Extraction des données de la carte grise"),
          _createElementVNode("br"),
          _createTextVNode("pour créer vos dossiers ")
        ], -1 /* HOISTED */)
      ]), 2 /* CLASS */),
      _createElementVNode("button", {
        class: _normalizeClass(['indicator-item', { active: $setup.currentIndex === 1 }]),
        onClick: _cache[1] || (_cache[1] = $event => ($setup.currentIndex = 1))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("div", { class: "indicator-item-slot" }, null, -1 /* HOISTED */),
        _createElementVNode("div", { class: "indicator-item-label" }, [
          _createTextVNode(" Suggestions d'emprunt"),
          _createElementVNode("br"),
          _createTextVNode("de vos véhicules de remplacement ")
        ], -1 /* HOISTED */)
      ]), 2 /* CLASS */)
    ]),
    ($setup.itemsContainerVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_Transition, {
            name: "fade-slide",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              ($setup.currentIndex === 0)
                ? (_openBlock(), _createBlock($setup["RegistrationCar"], { key: 0 }))
                : (_openBlock(), _createBlock($setup["ReplacementSuggestions"], { key: 1 }))
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}