<script setup>
import { ArrowBigRight, X } from "lucide-vue-next"
import { onMounted, ref } from "vue"

import InsuranceGroups from "../../../../../../cars/static_src/cars/components/_partials/InsuranceGroups.vue"

import { getDateDayName, getFullDate, getIsoString } from "../../../utils/date.js"

const monday = new Date("2025-01-06")
const days = new Array(3).fill(0).map((_, i) => {
  const date = new Date(monday)
  date.setDate(monday.getDate() + i)
  return date
})

const insuranceGroups = window.HOMEPAGE_INSURANCE_GROUPS
const cars = window.HOMEPAGE_CALENDAR_CARS
  .filter(c => [634, 636, 637].includes(c.id))
  .map((c, i) => ({ ...c, insurances: [insuranceGroups[i].insurances.map(i => ({ id: i.id }))[Math.floor(Math.random() * insuranceGroups[i].insurances.length)]] }))
const replacements = [
  {
    start_date: days[0],
    end_date: new Date("2025-01-10"),
    replacement_car: {
      registration: "AB-123-CD",
      brand: "Renault",
      model: "Twingo",
      insurance_groups: [insuranceGroups[0]],
    },
  },
  {
    start_date: days[0],
    end_date: new Date("2025-01-9"),
    replacement_car: {
      registration: "EF-456-GH",
      brand: "Renault",
      model: "Twingo",
      insurance_groups: [insuranceGroups[1]],
    },
  },
  {
    start_date: days[1],
    end_date: new Date("2025-01-09"),
    replacement_car: {
      registration: "GH-789-IJ",
      brand: "Citroën",
      model: "C3",
      insurance_groups: [insuranceGroups[2]],
    },
  },
]
const carsPerDay = ref({
  [getIsoString(days[0])]: [{ car: cars[0], replacement: undefined }, { car: cars[1], replacement: undefined }],
  [getIsoString(days[1])]: [{ car: cars[2], replacement: undefined }],
  [getIsoString(days[2])]: [],
})

const getCarMatchingInsuranceGroups = (car) => {
  return insuranceGroups.filter(ig => {
    return car.insurances.some(i => ig.insurances.map(ig => ig.id).includes(i.id))
  })
}

onMounted(() => {
  setTimeout(() => {
    carsPerDay.value = {
      [getIsoString(days[0])]: [{ car: cars[0], replacement: replacements[0] }, { car: cars[1], replacement: replacements[1] }],
      [getIsoString(days[1])]: [{ car: cars[2], replacement: replacements[2] }],
      [getIsoString(days[2])]: [],
    }
  }, 500)
})
</script>

<template>
  <div class="replacement-suggestions-container">
    <div class="replacement-suggestions">
      <div class="week-replacement-suggestions">
        <div
          v-for="day in days"
          :key="day"
          class="week-day"
        >
          <div class="week-day-name">
            {{ getDateDayName(day) }}
          </div>
          <div
            v-if="carsPerDay[getIsoString(day)]?.length > 0"
            class="week-day-cars"
          >
            <div
              v-for="{car, replacement} in carsPerDay[getIsoString(day)]"
              :key="car.id"
              :class="{
                'week-day-car': true,
                '-replacement': replacement !== undefined && replacement.replacement_car,
                '-no-hover': replacement && replacement.replacement_car,
              }"
            >
              <div class="car-details">
                {{ car.owner_last_name }} | {{ car.model }}
                <InsuranceGroups
                  :insurance-groups="getCarMatchingInsuranceGroups(car)"
                  :highlighted-insurances="car.insurances.map(i => i.id)"
                />
              </div>
              <div
                v-if="replacement && replacement.replacement_car"
                class="replacement"
              >
                <div class="delete-replacement-container">
                  <div class="delete-replacement-div">
                    <X size="18" />
                  </div>
                </div>
                <div class="replacement-dates">
                  <div class="replacement-date">
                    {{ getFullDate(replacement.start_date) }}
                  </div>
                  <ArrowBigRight class="replacement-date" />
                  <div class="replacement-date">
                    {{ getFullDate(replacement.end_date) }}
                  </div>
                </div>
                <div class="replacement-details">
                  <div class="replacement-car">
                    <span class="replacement-car-text">{{ replacement.replacement_car.registration }} - {{ replacement.replacement_car.brand }} - {{ replacement.replacement_car.model }}</span>
                  </div>
                  <InsuranceGroups :insurance-groups="replacement.replacement_car.insurance_groups" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="week-day-empty"
          >
            Aucun client en attente d'un VR
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../base/mixins";

.replacement-suggestions-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  align-self: start;
  gap: 1rem;
}

.replacement-suggestions {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  background-color: var(--content-bg--color-lighter);
  border-radius: var(--radius);
  border: 1px solid var(--color-border-primary);
  box-shadow: 0px 0px 4px 0px var(--color-shadow-primary);
  padding: 2rem 1rem;
}

.week-replacement-suggestions {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  width: 45rem;
  padding: 1rem 0;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.week {
  width: 100%;
  height: 100%;
}

.week-day-name {
  @include subtitle;
  text-align: center;
  width: 100%;
}

.week-day-cars {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .4rem;
  width: 100%;
}

.week-day-car {
  @include body;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--color-border-primary);
  padding-inline: 6px;
  height: 1.6rem;
  gap: .2rem;
  border-radius: var(--radius);
  overflow: hidden;
  transition: height 0.3s ease-in-out;

  &.-replacement {
    height: 9.8rem;
  }

  &.-no-hover:hover {
    cursor: auto;
    background-color: transparent;
  }
}

.car-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.week-day-empty {
  @include body;
}

.week-replacements-suggestions-buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 1rem;
}

.replacement {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  padding: .4rem;
  border: 1px dashed var(--color-border-primary);
  border-radius: var(--radius);
  width: 100%;
  transition: background-color var(--p-transition-duration) ease-in-out;
  &:hover {
    background-color: var(--content-bg--color-focus);
  }

  &:has(.delete-replacement-button:hover) {
    background-color: transparent;
  }
}

.replacement-details {
  display: flex;
  align-items: flex-start;
  gap: .4rem;
  width: 100%;
  padding-top: .2rem;
}

.replacement-car {
  @include body;
  display: flex;
  width: 100%;
  margin-top: -.4rem;
}

.replacement-car-text {
  max-width: 10rem;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: block;
  text-align: left;
}

.replacement-dates {
  display: flex;
  align-items: center;
  gap: .4rem;
  width: 100%;
}

.replacement-date {
  @include body;
  color: var(--color-text-submit);
}

.delete-replacement-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.delete-replacement-button {
  color: var(--color-icon);

  &:hover {
    color: var(--color-text-warning);
  }
}
</style>
