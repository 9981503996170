import { sortBy } from "lodash-es"
import { defineStore } from "pinia"

import { serializeCar } from "../../../../companies/static_src/companies/serializers/car.js"
import { getRepairingStateList } from "../../../../companies/static_src/companies/utils/cars.js"
import { deleteReplacement, getCars, updateCarInCharge, updateCarState } from "../services/api.js"
import { STATES_LIST } from "../utils/states.js"

const createBaseCarInChargeModel = (companyId) => {
  return {
    registration: null,
    brand: null,
    model: null,
    state: "coming",
    start_work: null,
    sheet_metal_work_hours: 0,
    painting_hours: 0,
    mechanic_hours: 0,
    sheet_metal_state: "todo",
    painting_state: "todo",
    mechanic_state: "todo",
    owner_first_name: null,
    owner_last_name: null,
    owner_phone_number: null,
    owner_phone_number_2: null,
    owner_email: null,
    owner_address: null,
    first_registration_date: null,
    end_valid_support_date: null,
    due_date: null,
    breakage_date: null,
    expertise_date: null,
    waiting_date: null,
    invoice_ready: false,
    need_replacement: false,
    expert_report_received: false,
    guarantee_given: false,
    repair_order: false,
    valid_support: false,
    is_deleted: false,
    foreground_note: "",
    background_note: "",
    from_tow_truck: false,
    wreck_delivered: false,
    is_seriously_damaged: false,
    company_id: companyId,
    insurances: [],
    replacements: [],
    delayed_works: [],
  }
}

export const useCarsStore = defineStore("cars-list", {
  state: () => ({
    cars: [],
    query: "",
    selectedStates: [],
    loadingData: false,
    currentUrl: "",
    env: null,
    focusedCar: null,
    carInChargeModalOpen: false,
    insuranceModalOpen: false,
    nextPage: 0,
    isHomepage: false,
  }),
  getters: {
    baseUrl: (state) => {
      return `/${state.env.company_slug}/`
    },
  },
  actions: {
    async init(env, params, loadCars = true) {
      this.loadingData = true

      // init env
      this.env = env

      // init hompage
      this.isHomepage = window.HOMEPAGE === "true"

      if (this.isHomepage) {
        this.loadingData = false
        return
      }

      // init filters
      this.query = params.query || ""
      if (params.selectedStates.length === 1 && params.selectedStates[0] === "") this.selectedStates = []
      else this.selectedStates = params.selectedStates.map((state) => STATES_LIST.find((s) => s.value === state)) || []

      // fetch data
      if (loadCars) await this.fetchCars(1)

      this.loadingData = false
    },
    async fetchCars(page) {
      const { data } = await getCars({ query: this.query, states: this.selectedStates.map((s) => s?.value) }, this.env.company_pk, page)
      if (page === 1) this.cars = data.results.map(serializeCar)
      else this.cars.push(...data.results.map(serializeCar))
      this.nextPage = data.next ? page + 1 : null
      this.sortCars()
    },
    resetFilters() {
      this.query = ""
      this.selectedStates = []
      this.nextPage = 1
      this.fetchCars(1)
    },
    openCarInChargeForm(car) {
      this.focusedCar = createBaseCarInChargeModel(this.env.company_pk)
      Object.assign(this.focusedCar, car)
      this.carInChargeModalOpen = true
    },
    updateCarInCharge(property, value, event = null) {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      this.focusedCar[property] = value
    },
    async addMofifications(newCar) {
      const index = this.cars.findIndex((car) => car.id === newCar.id)
      this.cars[index] = newCar
      if (!newCar.need_replacement && newCar.replacements.length > 0) await deleteReplacement(newCar.replacements[0].id)
      await updateCarInCharge({ ...newCar, insurance_ids: newCar.insurances.map((i) => i.id) })
      this.carInChargeModalOpen = false
    },
    addInsurance(insurance) {
      this.focusedCar.insurances.push(insurance)
    },
    removeInsurance(insurance) {
      const index = this.focusedCar.insurances.findIndex(i => i.id === insurance.id)
      this.focusedCar.insurances.splice(index, 1)
    },
    async updateCarState(object, newState, newRepairingStates) {
      const newRepairingStatesValues = newRepairingStates
        ? {
            sheet_metal_state: getRepairingStateList().find(s => s.label === newRepairingStates[0]).value,
            painting_state: getRepairingStateList().find(s => s.label === newRepairingStates[1]).value,
            mechanic_state: getRepairingStateList().find(s => s.label === newRepairingStates[2]).value,
          }
        : null
      const { data } = await updateCarState({ id: object.car.id, state: newState, ...newRepairingStatesValues })
      const index = this.cars.indexOf(object.car)
      if (index > -1) this.cars.splice(index, 1)
      this.cars.push(serializeCar(data))
      this.sortCars()
    },
    sortCars() {
      this.cars = sortBy(this.cars, [function(car) {
        const state = car.state
        switch (state) {
          case "todo":
            return 0
          case "coming":
            return 1
          case "expertise":
            return 2
          case "waiting":
            return 3
          case "breakage":
            return 4
          case "repairing":
            return 5
          case "reassembly":
            return 6
          case "finished":
            return 7
          case "delivered":
            return 8
        }
      }])
    },
  },
})
