import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "replacement-suggestions-container" }
const _hoisted_2 = { class: "replacement-suggestions" }
const _hoisted_3 = { class: "week-replacement-suggestions" }
const _hoisted_4 = { class: "week-day-name" }
const _hoisted_5 = {
  key: 0,
  class: "week-day-cars"
}
const _hoisted_6 = { class: "car-details" }
const _hoisted_7 = {
  key: 0,
  class: "replacement"
}
const _hoisted_8 = { class: "delete-replacement-container" }
const _hoisted_9 = { class: "delete-replacement-div" }
const _hoisted_10 = { class: "replacement-dates" }
const _hoisted_11 = { class: "replacement-date" }
const _hoisted_12 = { class: "replacement-date" }
const _hoisted_13 = { class: "replacement-details" }
const _hoisted_14 = { class: "replacement-car" }
const _hoisted_15 = { class: "replacement-car-text" }
const _hoisted_16 = {
  key: 1,
  class: "week-day-empty"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.days, (day) => {
          return (_openBlock(), _createElementBlock("div", {
            key: day,
            class: "week-day"
          }, [
            _createElementVNode("div", _hoisted_4, _toDisplayString($setup.getDateDayName(day)), 1 /* TEXT */),
            ($setup.carsPerDay[$setup.getIsoString(day)]?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.carsPerDay[$setup.getIsoString(day)], ({car, replacement}) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: car.id,
                      class: _normalizeClass({
                'week-day-car': true,
                '-replacement': replacement !== undefined && replacement.replacement_car,
                '-no-hover': replacement && replacement.replacement_car,
              })
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createTextVNode(_toDisplayString(car.owner_last_name) + " | " + _toDisplayString(car.model) + " ", 1 /* TEXT */),
                        _createVNode($setup["InsuranceGroups"], {
                          "insurance-groups": $setup.getCarMatchingInsuranceGroups(car),
                          "highlighted-insurances": car.insurances.map(i => i.id)
                        }, null, 8 /* PROPS */, ["insurance-groups", "highlighted-insurances"])
                      ]),
                      (replacement && replacement.replacement_car)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode($setup["X"], { size: "18" })
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, _toDisplayString($setup.getFullDate(replacement.start_date)), 1 /* TEXT */),
                              _createVNode($setup["ArrowBigRight"], { class: "replacement-date" }),
                              _createElementVNode("div", _hoisted_12, _toDisplayString($setup.getFullDate(replacement.end_date)), 1 /* TEXT */)
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("span", _hoisted_15, _toDisplayString(replacement.replacement_car.registration) + " - " + _toDisplayString(replacement.replacement_car.brand) + " - " + _toDisplayString(replacement.replacement_car.model), 1 /* TEXT */)
                              ]),
                              _createVNode($setup["InsuranceGroups"], {
                                "insurance-groups": replacement.replacement_car.insurance_groups
                              }, null, 8 /* PROPS */, ["insurance-groups"])
                            ])
                          ]))
                        : _createCommentVNode("v-if", true)
                    ], 2 /* CLASS */))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_16, " Aucun client en attente d'un VR "))
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}