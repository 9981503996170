import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "registration-car" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "car-incharge-form-block -row -no-padding" }
const _hoisted_4 = { class: "car-form-subtitle" }
const _hoisted_5 = { class: "car-incharge-form-block -row -no-padding" }
const _hoisted_6 = { class: "car-incharge-form-block -row -no-padding" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      class: _normalizeClass(["pdf-icon", { 'fade-in': $setup.showPdfIcon }])
    }, [
      _createElementVNode("use", {
        href: $setup.svgLink('ui', 'pdf')
      }, null, 8 /* PROPS */, _hoisted_2)
    ], 2 /* CLASS */)),
    _createVNode($setup["MoveRight"], {
      size: "42",
      class: _normalizeClass(["move-right-icon", { 'fade-in': $setup.showArrow }])
    }, null, 8 /* PROPS */, ["class"]),
    _createElementVNode("div", {
      class: _normalizeClass(["car-incharge-form-block -container", { 'fade-in': $setup.showForm }])
    }, [
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "registration",
            modelValue: $setup.registration,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.registration) = $event)),
            name: "registration",
            style: {"width":"100%"},
            disabled: ""
          }, null, 8 /* PROPS */, ["modelValue"]),
          _cache[8] || (_cache[8] = _createElementVNode("label", { for: "registration" }, "Plaque d'immatriculation", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "brand",
              modelValue: $setup.brand,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.brand) = $event)),
              name: "brand",
              style: {"min-width":"12.5rem"},
              disabled: ""
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[9] || (_cache[9] = _createElementVNode("label", { for: "brand" }, "Marque", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "model",
              modelValue: $setup.model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.model) = $event)),
              name: "model",
              style: {"min-width":"12.5rem"},
              disabled: ""
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[10] || (_cache[10] = _createElementVNode("label", { for: "model" }, "Modèle", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["DatePicker"], {
            id: "first_registration_date",
            modelValue: $setup.firstRegistrationDate,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.firstRegistrationDate) = $event)),
            name: "first_registration_date",
            style: {"width":"100%"},
            "date-format": "dd/mm/yy",
            disabled: ""
          }, null, 8 /* PROPS */, ["modelValue"]),
          _cache[11] || (_cache[11] = _createElementVNode("label", { for: "first_registration_date" }, "Date de 1ère immatriculation", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "address",
            modelValue: $setup.address,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.address) = $event)),
            name: "address",
            style: {"width":"100%"},
            disabled: ""
          }, null, 8 /* PROPS */, ["modelValue"]),
          _cache[12] || (_cache[12] = _createElementVNode("label", { for: "address" }, "Adresse", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["UserRound"], { size: "24" }),
        _cache[13] || (_cache[13] = _createTextVNode(" Propriétaire "))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner-first-name",
              modelValue: $setup.ownerFirstName,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.ownerFirstName) = $event)),
              name: "owner-first-name",
              style: {"min-width":"12.5rem"},
              disabled: ""
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[14] || (_cache[14] = _createElementVNode("label", { for: "owner-first-name" }, "Prénom", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner-last-name",
              modelValue: $setup.ownerLastName,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.ownerLastName) = $event)),
              name: "owner-last-name",
              style: {"min-width":"12.5rem"},
              disabled: ""
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[15] || (_cache[15] = _createElementVNode("label", { for: "owner-last-name" }, "Nom", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner-phone",
              modelValue: $setup.ownerPhone,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.ownerPhone) = $event)),
              name: "owner-phone",
              style: {"min-width":"12.5rem"},
              disabled: ""
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[16] || (_cache[16] = _createElementVNode("label", { for: "owner-phone" }, "Numéro de téléphone 1", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner-phone-2",
              name: "owner-phone-2",
              style: {"min-width":"12.5rem"},
              disabled: ""
            }),
            _cache[17] || (_cache[17] = _createElementVNode("label", { for: "owner-phone-2" }, "Numéro de téléphone 2", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        })
      ])
    ], 2 /* CLASS */)
  ]))
}