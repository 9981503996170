<script setup>
import { onMounted } from "vue"

import StaffTable from "../../../../companies/static_src/companies/components/staff/StaffTable.vue"

import useStaffStore from "../../../../companies/static_src/companies/stores/staff.js"

const staff = useStaffStore()

onMounted(staff.init)
</script>

<template>
  <StaffTable />
</template>
