<script setup>
import { MoveRight, UserRound } from "lucide-vue-next"
import DatePicker from "primevue/datepicker"
import FloatLabel from "primevue/floatlabel"
import InputText from "primevue/inputtext"
import { onMounted, ref } from "vue"

import { svgLink } from "../../../utils/svg.js"

const registration = ref(null)
const brand = ref(null)
const model = ref(null)
const address = ref(null)
const ownerLastName = ref(null)
const ownerFirstName = ref(null)
const ownerPhone = ref(null)
const firstRegistrationDate = ref(null)

const showPdfIcon = ref(false)
const showArrow = ref(false)
const showForm = ref(false)
const fillForm = ref(false)

onMounted(() => {
  setTimeout(() => {
    showPdfIcon.value = true
  }, 200)

  setTimeout(() => {
    showArrow.value = true
  }, 500)

  setTimeout(() => {
    showForm.value = true
  }, 800)

  setTimeout(() => {
    fillForm.value = true
    registration.value = "AB-123-CD"
    brand.value = "Citroën"
    model.value = "C3"
    address.value = "1 rue de la Paix, 75000 Paris"
    ownerLastName.value = "Dupont"
    ownerFirstName.value = "Jean"
    ownerPhone.value = "01 23 45 67 89"
    firstRegistrationDate.value = new Date("2019-01-01")
  }, 1300)
})
</script>

<template>
  <div class="registration-car">
    <svg
      class="pdf-icon"
      :class="{ 'fade-in': showPdfIcon }"
    >
      <use :href="svgLink('ui', 'pdf')" />
    </svg>
    <MoveRight
      size="42"
      class="move-right-icon"
      :class="{ 'fade-in': showArrow }"
    />
    <div
      class="car-incharge-form-block -container"
      :class="{ 'fade-in': showForm }"
    >
      <FloatLabel variant="on">
        <InputText
          id="registration"
          v-model="registration"
          name="registration"
          style="width: 100%;"
          disabled
        />
        <label for="registration">Plaque d'immatriculation</label>
      </FloatLabel>
      <div class="car-incharge-form-block -row -no-padding">
        <FloatLabel variant="on">
          <InputText
            id="brand"
            v-model="brand"
            name="brand"
            style="min-width: 12.5rem;"
            disabled
          />
          <label for="brand">Marque</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputText
            id="model"
            v-model="model"
            name="model"
            style="min-width: 12.5rem;"
            disabled
          />
          <label for="model">Modèle</label>
        </FloatLabel>
      </div>
      <FloatLabel variant="on">
        <DatePicker
          id="first_registration_date"
          v-model="firstRegistrationDate"
          name="first_registration_date"
          style="width: 100%;"
          date-format="dd/mm/yy"
          disabled
        />
        <label for="first_registration_date">Date de 1ère immatriculation</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <InputText
          id="address"
          v-model="address"
          name="address"
          style="width: 100%;"
          disabled
        />
        <label for="address">Adresse</label>
      </FloatLabel>
      <div class="car-form-subtitle">
        <UserRound size="24" />
        Propriétaire
      </div>
      <div class="car-incharge-form-block -row -no-padding">
        <FloatLabel variant="on">
          <InputText
            id="owner-first-name"
            v-model="ownerFirstName"
            name="owner-first-name"
            style="min-width: 12.5rem;"
            disabled
          />
          <label for="owner-first-name">Prénom</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputText
            id="owner-last-name"
            v-model="ownerLastName"
            name="owner-last-name"
            style="min-width: 12.5rem;"
            disabled
          />
          <label for="owner-last-name">Nom</label>
        </FloatLabel>
      </div>
      <div class="car-incharge-form-block -row -no-padding">
        <FloatLabel variant="on">
          <InputText
            id="owner-phone"
            v-model="ownerPhone"
            name="owner-phone"
            style="min-width: 12.5rem;"
            disabled
          />
          <label for="owner-phone">Numéro de téléphone 1</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputText
            id="owner-phone-2"
            name="owner-phone-2"
            style="min-width: 12.5rem;"
            disabled
          />
          <label for="owner-phone-2">Numéro de téléphone 2</label>
        </FloatLabel>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../base/mixins";

.registration-car {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  align-self: start;
  gap: 1rem;
}

.pdf-icon {
  width: 6rem;
  height: 6rem;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.move-right-icon {
  color: var(--color-button-bg-submit);
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.car-incharge-form-block {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 100%;

  &.-container {
    max-width: 28rem;
    background-color: var(--content-bg--color-lighter);
    border-radius: var(--radius);
    border: 1px solid var(--color-border-primary);
    box-shadow: 0px 0px 4px 0px var(--color-shadow-primary);
    padding: 2rem 1rem;
    opacity: 0;
    transition: opacity 0.6s ease-in;
  }

  &.-row {
    flex-direction: row;
    justify-content: space-between;
  }

  &.-no-padding {
    padding: 0;
  }
}

.car-form-subtitle {
  @include subtitle;
  display: flex;
  align-items: center;
  gap: .4rem;
  margin-top: .4rem;
}

.fade-in {
  opacity: 1 !important;
  transform: translateX(0) !important;
}
</style>
