<script setup>
import { onMounted, ref } from "vue"

import RegistrationCar from "../components/homepage/ai-features/RegistrationCar.vue"
import ReplacementSuggestions from "../components/homepage/ai-features/ReplacementSuggestions.vue"

const currentIndex = ref(0)
const aiFeaturesContainerRef = ref(null)
const itemsContainerVisible = ref(false)

onMounted(() => {
  // eslint-disable-next-line compat/compat
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        itemsContainerVisible.value = true
      }
    })
  }, { threshold: 0.9 })
  observer.observe(aiFeaturesContainerRef.value)
})
</script>

<template>
  <div
    ref="aiFeaturesContainerRef"
    class="ai-features"
  >
    <div class="indicator-list">
      <div class="indicator-list-header">
        L'Intelligence Artificielle<br> à votre service
      </div>
      <button
        :class="['indicator-item', { active: currentIndex === 0 }]"
        @click="currentIndex = 0"
      >
        <div class="indicator-item-slot" />
        <div class="indicator-item-label">
          Extraction des données de la carte grise<br>pour créer vos dossiers
        </div>
      </button>
      <button
        :class="['indicator-item', { active: currentIndex === 1 }]"
        @click="currentIndex = 1"
      >
        <div class="indicator-item-slot" />
        <div class="indicator-item-label">
          Suggestions d'emprunt<br>de vos véhicules de remplacement
        </div>
      </button>
    </div>
    <div
      v-if="itemsContainerVisible"
      class="items-container"
    >
      <transition
        name="fade-slide"
        mode="out-in"
      >
        <RegistrationCar v-if="currentIndex === 0" />
        <ReplacementSuggestions v-else />
      </transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../base/mixins";

.ai-features {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  gap: 1rem;
}

.indicator-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.indicator-list-header {
  @include title;
  text-align: left;
  font-size: 2rem;
  line-height: 2.1rem;
}

.indicator-item {
  display: flex;
  gap: 1rem;
  padding: .4rem 0;

  &:hover {
    background-color: transparent;
  }
}

.indicator-item-slot {
  width: .3rem;
  height: 2.5rem;
  border-radius: 6px;
  background-color: var(--content-bg--color-lighter);
}

.indicator-item-label {
  @include body;
  color: var(--color-text-secondary);
  text-align: left;

  &:hover {
    color: var(--color-text-primary);
  }
}

.indicator-item:not(.active):hover .indicator-item-slot {
  background-color: var(--content-bg--color-contrast);
}

.indicator-item.active .indicator-item-slot {
  background-color: var(--p-form-field-focus);

  &:hover {
    background-color: var(--p-form-field-focus);
  }
}

.indicator-item.active .indicator-item-label {
  color: var(--color-text-primary);
}

.items-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
  padding: 1rem;
}

.fade-slide-enter-active, .fade-slide-leave-active {
  transition: opacity var(--p-transition-duration) ease, transform var(--p-transition-duration) ease;
}

.fade-slide-enter-from {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
